import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Remember all the videos of two-year-olds being able to swipe an iPhone or iPad? `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` see much the same thing happening now with devices like Alexa and Google Home — kids already know how to interact with them.`}</p>
    <p>{`One of our co-founders, Jesse, has two little kids and they run up to Alexa and say, “Play the happy dance, play star wars…” They know how to interact.`}</p>
    <p>{`Similarly, being in the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`bot analytics`}</a>{` space, we’re often asked what is the “killer bot” — with the questioner drawing an analogy to mobile and “killer apps.”`}</p>
    <p>{`We’d argue, however, much like in mobile, peopled didn’t buy the iPhone because of a killer app, they bought it because of the device itself.`}</p>
    <p>{`We often hear how behavior changing these devices are — from people listening to more music, to changing where they work in their homes to be closer to the devices, to enabling home automation, and more.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` recently hosted a meetup in New York focused on what brands are doing in the bot / conversational interface space.`}</p>
    <p>{`The discussion quickly turned to voice enabled interfaces.`}</p>
    <p>{`Our panel consisted of: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/courtney-nelson-13838721"
      }}>{`Courtney Nelson`}</a>{` of BDMI, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mobiledougrobinson/"
      }}>{`Doug Robinson`}</a>{` of Fresh Digital Group, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/dlesicko"
      }}>{`Drew Lesicko`}</a>{` of AOL/Oath, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/markcwebster/"
      }}>{`Mark Webster`}</a>{` of Say Spring, and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/roblamberson"
      }}>{`Rob Lamberson`}</a>{` of IPGMedia/Ansible.`}</p>
    <p>{`The brands our panelists work for, or with, are working on a variety of different use cases — including Skills for automotive, alcohol/beverage firms, pharma, media, CPG, education, finance, and more.`}</p>
    <p>{`For brands, the use cases are still being figured out.`}</p>
    <p>{`As Courtney mentioned, people don’t necessarily want what we think they want from a big brand — providing the same content that one could receive on their website. At AOL, Drew and his team consider, if you’re going to have a conversation with the Huffington Post, what would that be like?`}</p>
    <p>{`Media companies are trying to figure out the right way to share short snippets of content that don’t live on voice the same way they do on the web — and provide surprising features too to delight.`}</p>
    <p>{`From a pure media standpoint, “Flash Briefings” (quick news segments) may currently be more important than Skills, but the brands are also looking to utility — getting weather, updating your fantasy sports teams, and more.`}</p>
    <p>{`An interesting use case Mark discussed was the “unboxing” Skill for retailers and brands — a Skill to provide help and more info for a product purchased. For example, a consumer who purchased a power tool, could ask Alexa how to use it.`}</p>
    <p>{`Skills aren’t yet about monetization for brands — they’re about engagement and retention.`}</p>
    <p>{`As Courtney pointed out, brands aren’t one dimensional providers of a product — now, they’re a lifestyle image. It’s about content marketing. Brands need to be everywhere — a lifestyle play rather than a single product offering play.`}</p>
    <p>{`Creating a personality goes a long way to increasing engagement. We see this first hand in the analytics. When developers create a personality and respond to the different types of utterances and messages users submit, the engagement goes up.`}</p>
    <p>{`Rob and the team at Ansible incorporate personalities into their Skills and bots. They created a Skill for Zima, a 90s era “beer” brought back for a limited time. The Skill had a persona of someone who just woke up from the 90s and featured fun trivia, 90s music and more. Similarly, Ansible gave the Kia bot a “snarky” personality to make it more engaging.`}</p>
    <p>{`In addition to creating a personality, context is important.`}</p>
    <p>{`People generally have their phone with them all the time. They may use the phone to find where something is located, whereas they would use Alexa or Google Home to find out who, or what, something is when at home.`}</p>
    <p>{`At the same time, users on mobile phones may be more comfortable with texting, hence the popularity of chatbots, whereas at home, one might be more comfortable speaking and using a device like Alexa or Google Home.`}</p>
    <p>{`Each of the platforms has its benefits. Brands are going where the users are and trying to provide content relative to the platform and device.`}</p>
    <p>{`The success metrics depend on use case and vary by Skill and brand. At Dashbot, we provide tools to help measure success — including retention metrics, engagement, and goal funnels.`}</p>
    <p>{`The panelists are looking at usage, including retention and engagement.`}</p>
    <p>{`For big brands, the definition of success may be different from what they are currently used to. As Drew pointed out, some brands are only interested in reaching 100M unique users. Given we’re in the early days, the numbers are currently a lot less than what is seen in web.`}</p>
    <p>{`The brands do want to see value though and that is something many of the panelists are trying to figure out as well — including whether that is future monetization or advertising opportunities.`}</p>
    <p>{`All the panelist agreed, we’re still in the early days of voice interfaces and chatbots. Natural Language Processing is getting better, and devices like Alexa and Google Home are getting better every day.`}</p>
    <p>{`While some brands are still in the experimental stage, developing voice Skills and chatbots through innovation teams and experimental budgets, other brands are starting to see them as a core part of their strategy — something the Ansible team sees from their clients.`}</p>
    <p>{`The Kia Niro bot developed by Ansible started out answering questions about price and EV/Hybrid technology, but evolved into helping find locations to purchase or test drive, as well as owner manual elements.`}</p>
    <p>{`Discovery is still one of the biggest issues. Currently the panelists see media as the biggest driver — whether it’s social media, paid, or organic. The platforms are working on the issue though — and getting closer. As Drew pointed out, the space is moving faster than people think and we’re getting closer to having an “Apple Store” experience.`}</p>
    <p>{`An interesting point Mark brought up was around the usage of Skills in the Alexa store — that some say only about 10% of the Skills are used, but that’s similar to mobile. We’re just at the early days and some people are experimenting.`}</p>
    <p>{`Drew also brought up an interesting analogy. When movies first came out, studios were just filming plays as that’s what people knew. We’re at the same stage now. There are so many new possibilities in these environments that folks haven’t even discovered yet. Who would have thought in the mobile days, someone would use the microphone as part of a game, but that’s what Smule did. It will be interesting to see what kind of conversational games people invent here as well.`}</p>
    <p>{`The promise is there.`}</p>
    <p>{`Conversational interfaces provide great insights into users thoughts and interests beyond web and mobile. In web and mobile, the intents and usecases are pre-defined — clicking links and buttons. With conversational interfaces, users tell you what they want from your Skill or chatbot and what they think of it after as well — there’s direct feedback.`}</p>
    <p>{`What’s exciting, as Doug pointed out, is some of the largest players are actively pursuing this space and keep improving — Google, Amazon, Microsoft, Facebook, Apple, and Samsung.`}</p>
    <p>{`The space will continue to evolve and get better — we’re all part of this journey contributing to its success and helping move the industry forward.`}</p>
    <p>{`Watch the video here:`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`We provide `}<a parentName="p" {...{
        "href": "https://voice.dashbot.io/"
      }}>{`Voice specific analytics`}</a>{` for Alexa and Google Home.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Alexa, Google Home, Facebook Messenger, Slack, Twitter, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      